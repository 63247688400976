body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

/* bulma uses `.select select[multiple]` but this is a DOM element that
   you can't get with React/JSX. If you do `<select multiple ...` or
   `<select multiple={true} ...` React will render this as
   `<select multiple="" ...` so we need to extend the default Bulma style
   sheets for these with the extra `=""`.

   Note: Perhaps we don't know this after upgrading to Bulma 0.4.4
   https://bugzilla.mozilla.org/show_bug.cgi?id=1383249
*/
.select select[multiple=''] {
  height: unset;
  padding: 0;
}

.select select[multiple=''] option {
  padding: 0.5em 1em;
}

table.is-fullwidth tbody tr th {
  width: 260px;
}

.redirect-urls li {
  line-height: 1;
}
.redirect-urls .url {
  font-size: 90%;
}
