th.bool-row {
  max-width: 40px;
  overflow: hidden;
  text-overflow: hidden;
  white-space: nowrap;
}


table.files-table th,
table.files-table td {
  font-size: 90%;
}
table.files-table td.file-key {
  font-size: 80%;
  line-height: 1.1;
}

dl dt { font-weight: bold; }

th.sortable {
  cursor: pointer;
}
